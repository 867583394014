import Button from "react-bootstrap/Button";

const CoralButton = ({width, height, fontSize = null, title, onClick, loading = false, disabled = false}) => {
    if (title) {
        return (

            <Button
                className={`${!loading && !disabled && "cursor-pointer"} ${disabled && "cursor-not-allowed"}`}
                style={{
                    width: `${width}px`,
                    height: `${height}px`,
                }}
                onClick={() => {
                    if (loading) return
                    onClick()
                }}
            >
                {!loading ? (
                    <span style={{fontSize: fontSize || `${height / 3 * 2}px`}}>
                    {title}
                </span>
                ) : (
                    <span style={{fontSize: fontSize || `${height / 3 * 2}px`}}>
                    ...
                </span>
                )}
            </Button>
        )
    }
}

export default CoralButton