import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import '../assets/css/MintProgress.css';


const MintProgress = ({minted, maxTokens}) => {
    return (
        <>
            <Row className="gen-loader">
                <div
                    className="gen-box-background"
                    style={{
                        width: `${
                            minted < maxTokens / 5
                                ? (minted / (maxTokens / 5)) * 100
                                : (minted / maxTokens) * 100
                        }%`,
                    }}
                />
                <Col className="gen-box">GEN 0
                    <span className="eon">10000 EON</span>
                </Col>
                <Col className="gen-box">GEN 1
                    <span className="eon">10000 EON</span>
                </Col>
                <Col className="gen-box">GEN 2
                    <span className="eon">10000 EON</span>
                </Col>
                <Col className="gen-box">GEN 3
                    <span className="eon">10000 EON</span>
                </Col>
                <Col className="gen-box">GEN 4
                    <span className="eon">10000 EON</span>
                </Col>
                <Col className="gen-box">GEN 5
                    <span className="eon">10000 EON</span>
                </Col>

            </Row>
            <h2 className="minted-text text-center">
                {minted} / {maxTokens} MINTED
            </h2>
        </>

    );
};

export default MintProgress;
