import { Nav } from "react-bootstrap";

import { FaTwitter } from "react-icons/fa";
import { BsDiscord } from "react-icons/bs";

export default function NavIconLinks() {
  return (
    <Nav className="justify-content-center icons">
      <Nav.Link href="#Etherscan">
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 550 550"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            id="Path_1"
            className="st0"
            d="M127.1,264.4c0-12.4,10-22.4,22.4-22.4c0,0,0.1,0,0.1,0l37.3,0.1c12.4,0,22.4,10,22.4,22.4v141
			c4.2-1.2,9.6-2.6,15.5-4c8.4-2,14.4-9.5,14.4-18.2V208.5c0-12.4,10-22.4,22.4-22.4h0H299c12.4,0,22.4,10,22.4,22.4v162.3
			c0,0,9.3-3.8,18.5-7.6c6.9-2.9,11.4-9.7,11.4-17.2V152.4c0-12.4,10-22.4,22.4-22.4h37.4c12.4,0,22.4,10,22.4,22.4v159.3
			c34.4-24,65.1-52.9,91.3-85.7c7.7-10,9.8-23.2,5.7-35.1C482.6,53.3,332.2-19.5,194.6,28.4C56.9,76.3-15.9,226.7,32,364.3
			c5.4,15.5,12.2,30.5,20.4,44.7c6.5,11.2,18.9,17.6,31.8,16.5c7.1-0.6,15.9-1.5,26.4-2.7c9.4-1.1,16.5-9,16.6-18.5L127.1,264.4"
          />
          <path
            id="Path_2"
            className="st0"
            d="M126.3,490.5c118,85.7,283.1,59.6,368.8-58.3c32.8-45.1,50.5-99.4,50.5-155.2
			c0-6.1-0.3-12.1-0.7-18.1C448.4,402.8,270.4,470,126.3,490.5"
          />
        </svg>
      </Nav.Link>

      <Nav.Link href="#home">
        <svg
          stroke="currentColor"
          fill="currentColor"
          strokeWidth="0"
          viewBox="0 0 550 550"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M282.2,13C138.1,13,21.2,129.9,21.2,274c0,144.1,116.8,260.9,260.9,260.9c144.1,0,260.9-116.8,260.9-260.9
			C543.1,129.9,426.3,13.1,282.2,13z M150.1,282.9l1-1.6L219,175.3c0.7-1.1,2.1-1.4,3.2-0.8c0.5,0.3,0.8,0.8,1,1.3
			c11.5,25.6,20.9,56.9,16.7,76.7c-3.1,10.4-7.6,20.2-13.6,29.2c-0.6,1.6-1.4,3-2.6,4.2c-0.5,0.6-1.3,1-2.1,1h-69.9
			c-1.5-0.5-2.3-2.2-1.8-3.7C150,283.2,150.1,283,150.1,282.9z M452.3,319.4c0,1-0.5,1.6-1.6,2.1c-11.8,4.4-22.3,11.6-30.8,20.9
			c-19.3,26.6-33.9,64.7-66.3,64.7H217.4c-48.3-0.1-87.3-39.4-87.2-87.7c0,0,0,0,0,0v-1.6c0.1-1.1,1-2,2.1-2.1h76.7
			c1.4,0.1,2.5,1.2,2.6,2.6c-0.4,5,0.5,10.1,2.6,14.6c4.6,9.1,13.9,14.7,24,14.6h37.6v-29.2h-37.1c-1.3,0.1-2.4-0.9-2.5-2.2
			c0-0.5,0.1-1,0.4-1.4c0.6-0.6,1.2-1.3,1.6-2.1c3.7-5.2,8.3-12.5,13.6-21.4c3.7-6,6.8-12.3,9.4-18.8c0.6-1.2,1.1-2.4,1.6-3.7
			c0.5-2.1,1.6-4.2,2.1-5.8l1.6-4.7c1.1-5.7,1.6-11.4,1.6-17.2c0.1-2.4-0.1-4.9-0.5-7.2c0-2.6-0.5-5.2-0.5-7.8
			c-0.1-2.3-0.4-4.6-1-6.8c-0.4-3.5-1.1-7-2.1-10.4l-0.5-1c-0.5-2.3-1.2-4.6-2.1-6.8c-2-7.1-4.4-14.1-7.2-20.9l-3.1-7.8
			c-1.6-3.7-3.1-7.2-4.7-10.4c-0.5-1.6-1.6-2.6-2.1-4.2c-0.5-1.6-1.6-3.1-2.1-4.7l-1.6-3.1l-4.7-8.3c-0.5-1,0.5-2.6,1.6-2.1
			l28.7,7.8l3.7,1l4.2,1l1.6,0.5v-17.2c-0.1-8.2,6.4-14.9,14.6-15.1c7.9-0.2,14.4,6,14.6,13.9c0,0.2,0,0.5,0,0.7v25l3.1,1
			c0.3,0,0.5,0.2,0.5,0.5c0,0,0,0,0,0c1,1,2,1.8,3.1,2.6c1.1,1.2,2.3,2.2,3.7,3.1c2.6,2.1,6.3,5.2,9.9,8.3c1,1,2.1,1.6,2.6,2.6
			c5.3,4.8,10.4,9.8,15.1,15.1c1.6,1.6,2.6,3.1,4.2,4.7c1.6,1.6,2.6,3.7,4.2,5.2c1.6,2.1,3.7,4.7,5.2,6.8c0.8,1.2,1.5,2.4,2.1,3.7
			c2.1,3.1,3.7,6.3,5.8,9.4c0.5,1.6,1.6,3.1,2.1,4.7c2.2,4.1,3.6,8.5,4.2,13c0.4,0.8,0.6,1.7,0.5,2.6c0.5,1.3,0.6,2.8,0.5,4.2
			c0.3,4.5,0.2,9.1-0.5,13.6c-0.5,2.1-1,3.7-1.6,5.8c-0.5,2-1.2,3.9-2.1,5.8c-1.7,3.8-3.7,7.4-5.8,11c-0.8,1.4-1.7,2.8-2.6,4.2
			c-1.1,1.3-1.9,2.7-2.6,4.2c-1,1.6-2.6,3.1-3.7,4.7c-1.1,1.7-2.3,3.3-3.7,4.7c-1.6,2.1-3.7,4.2-5.2,6.3c-2.4,2.4-4.8,4.8-7.2,7.2
			c-1.4,1.5-3,2.9-4.7,4.2l-3.1,2.6c-0.4,0.4-1,0.6-1.6,0.5h-23v29.2h28.7c6.3,0.1,12.4-2.2,17.2-6.3c1.6-1.6,8.9-7.8,17.7-17.2
			c0.5-0.5,0.5-0.5,1-0.5l79.3-23c3.1-1.6,4.1-0.5,4.1,1V319.4z"
          />
        </svg>
      </Nav.Link>

      <Nav.Link href="#features">
        <FaTwitter />
      </Nav.Link>

      <Nav.Link href="#pricing">
        <BsDiscord />
      </Nav.Link>
    </Nav>
  );
}
