import React from "react";
import ReactDOM from "react-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import Whitepaper from "./WhitePaper";
import ToS from "./ToS";
import Play from "./Play";
import { ApolloProvider, InMemoryCache, ApolloClient } from "@apollo/client";

const client = new ApolloClient({
  ssrMode: true,
  uri: process.env.REACT_APP_SUBGRAPH,
  cache: new InMemoryCache(),
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <React.StrictMode>
      <BrowserRouter>
        <Routes>
          <Route exact path="/whitepaper" element={<Whitepaper />} />
          <Route exact path="/tos" element={<ToS />} />
          <Route exact path="/play" element={<Play />} />
          <Route path="/" element={<App />} />
        </Routes>
      </BrowserRouter>
    </React.StrictMode>
  </ApolloProvider>,
  document.getElementById("root")
);
