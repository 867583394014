import {useState} from "react";
import {parseBigNumber} from "../utils/ethereum";
import {BigNumber} from "@ethersproject/bignumber";
import "../assets/css/Stats.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const Stats = ({stats, users}) => {
    const [global, setGlobal] = useState(true);

    const stakedPercentage = (_global) => {
        return (((parseInt(_global?.colonistMinted) + parseInt(_global?.piratesMinted)) / parseInt(_global?.totalSupply)) * 100).toFixed(2)
    }

    return (

        <Col xs={12} md={6}>
            <Row>
                <Col xs={12} md={4} className="container-bordered container-blurred">
                    <h2>GAME STATUS</h2>
                </Col>
            </Row>

            <Row className="container-blurred container-bordered stats-container">
                    <div
                        className={`${
                            global ? "underline text-red text-xl" : "text-gray"
                        } font-console cursor-pointer`}
                        onClick={() => setGlobal(true)}
                    >

                    </div>
                    <div
                        className={`${
                            !global ? "underline text-red text-xl" : "text-gray"
                        } font-console cursor-pointer`}
                        onClick={() => setGlobal(false)}
                    >
                    </div>

                    {global ? (
                        <Row>
                            <Col xs={12} md={6}  className="stats-left">
                                <div className="stats-row">
                                    <span className="stats-title">COLONISTS MINTED:</span>
                                    <span className="stats-value">{stats?.colonistMinted}</span>
                                </div>

                                <div className="stats-row">
                                    <span className="stats-title">COLONISTS STAKED:</span>
                                    <span className="stats-value">{stats?.colonistMinted}</span>
                                </div>

                                <div className="stats-row">
                                    <span className="stats-title">PIRATES MINTED:</span>
                                    <span className="stats-value">{stats?.piratesMinted}</span>
                                </div>
                                <div className="stats-row">
                                    <span className="stats-title">PIRATES STAKED:</span>
                                    <span className="stats-value">{stats?.piratesMinted}</span>
                                </div>
                                <div className="stats-row">
                                    <span className="stats-title">STAKED:</span>
                                    <span className="stats-value">{stakedPercentage(stats)} %</span>
                                </div>
                            </Col>
                            <Col xs={12} md={6} className="stats-right">
                                <div className="stats-row">
                                    <span className="stats-title">COLONISTS KIDNAPPED:</span><span
                                    className="stats-value">0</span></div>
                                <div className="stats-row">
                                    <span className="stats-title">PIRATES KIDNAPPED:</span><span
                                    className="stats-value">0</span></div>
                                <div className="stats-row">
                                    <span className="stats-title">XXX CLAIMED:</span><span
                                    className="stats-value">0</span></div>


                                <div className="stats-row">
                                    <span className="stats-title"> #EON Claimed:{" "}</span>
                                    <span className="stats-value"> {stats?.eonClaimed
                                        ? parseBigNumber(BigNumber.from(stats?.eonClaimed))
                                        : 0}</span>
                                </div>
                            </Col>
                        </Row>

                    ) : (
                        <ol type="1" className="w-full font-console">
                            {users ? (
                                users.map((user, index) => {
                                    if (BigNumber.from(user.claimedEon).eq(BigNumber.from("0")))
                                        return null;
                                    return (
                                        <li>
                                            <div className="flex justify-between mb-2">
                                                <a
                                                    className="cursor-pointer"
                                                    href={`https://opensea.io/${user.id}`}
                                                    target="_blank"
                                                    rel="noreferrer"
                                                >
                    <span className="text-red text-xl mr-4">
                {index + 1 + "."}
                    </span>
                                                    {user.id.slice(0, 2) +
                                                    " " +
                                                    user.id.slice(2, 6) +
                                                    " ... " +
                                                    user.id.slice(-4)}{" "}
                                                    ↗
                                                </a>
                                                <div>{parseBigNumber(user.claimedEon, 0)} $EON</div>
                                            </div>
                                        </li>
                                    );
                                })
                            ) : (
                                <div>Fetching...</div>
                            )}
                        </ol>
                    )}

            </Row>


            <Row className="eon-row">
                <Col xs={12} md={5} className="container-bordered container-blurred eon-container">
                    <h2>YOUR <span className="brand-green">EON</span> : </h2>
                    <p className="eon-value">154,645,456.57776</p>
                </Col>
            </Row>
        </Col>


    );
};

export default Stats;
