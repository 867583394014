import { Button, Container } from "react-bootstrap";

import Navbar from "./components/Navbar";
import NavIconLinks from "./components/NavIconLinks";
import logo from "./assets/images/galaxy-games-logo.png";
import bgVideo from "./assets/videos/Station.mp4";

const Main = ({ wallet, chain, reload }) => {
  return (
    <main className="vh-100 d-flex flex-column justify-content-center">
      <video autoPlay muted loop id="bgLoop" src={bgVideo} type="video/mp4" />

      <Navbar wallet={wallet} chain={chain} />

      <Container className="d-flex flex-column align-items-center justify-content-center ">
        <div className="d-block d-md-none">
          <NavIconLinks />
        </div>

        <img className="home-logo w-100" src={logo} alt="Galaxy Game" />
        <h1 className="header visually-hidden">Galaxy Game</h1>
      </Container>

      <Container
        className="d-flex flex-column flex-md-row align-items-center justify-content-evenly"
        style={{ transform: "translateY(20px)" }}
      >
        <Button href={"./Play"} className="m-4">
          GAME
        </Button>

        <Button href={"./WhitePaper"} className="m-4">
          FAQ
        </Button>
      </Container>
    </main>
  );
};

export default Main;
