import React from 'react'
import { connectMetamask } from '../utils/ethereum'

const MetamaskConnect = () => {
  return (
    <span
      onClick={connectMetamask}
    >
        Connect
    </span>
  )
}

export default MetamaskConnect
