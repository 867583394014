import "./App.css";
import { useEffect, useState } from "react";
import { WalletHook } from "./utils/ethereum";
import { eonBalance } from "./utils/eon";
import Modal from "react-modal";
import Main from "./Main";

const App = () => {
    const { wallet, chain } = WalletHook();
    const [eon, setEon] = useState("?");

    useEffect(() => {
        Modal.setAppElement("body");
        console.log("Mounting app");
    }, []);

    useEffect(() => {
        const loadEon = async () => {
            if (!wallet) return;
            setEon(await eonBalance(wallet));
        };
        loadEon();
    }, [wallet, chain]);

    return (
        
        <Main
            wallet={wallet}
            chain={chain}
            wool={eon}
            reload={async () => {
                if (!wallet) return;
                setEon(await eonBalance(wallet));
            }}
        />
    );
};

export default App;
