import { switchToMainnet } from "../utils/ethereum"

const MainnetConnect = () => {
  return (
    <span
      onClick={switchToMainnet}
    >
        Switch to Mainnet
    </span>
  )
}

export default MainnetConnect
