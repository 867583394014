import { Col, Container, Navbar } from "react-bootstrap";
import NavIconLinks from "./NavIconLinks";

import EthereumInteraction from "./EthereumInteraction";

export default function NavigationBar({ wallet, chain }) {
  return (
    <Navbar fixed="top" className="pt-4">
      <Container>
        <Col xs={0} md={3} />
        <Col xs={6} md={3} className="d-md-block d-none">
          <NavIconLinks />
        </Col>
        <Col className="justify-content-end text-end" xs={6} md={3}>
          <EthereumInteraction wallet={wallet} chain={chain} />
        </Col>
        {/*<Navbar.Brand href="#home">Home</Navbar.Brand>*/}
      </Container>
    </Navbar>
  );
}
