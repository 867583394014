import { Contract, utils, BigNumber } from "ethers";
import { _getProvider } from "./ethereum";
import PYTHEAS_ABI from "./abi/pytheas.abi";

export const stake = async (account, tokenIds) => {
  const provider = _getProvider();
  if (!provider) throw new Error("Unable to connect to wallet");
  const signer = provider.getSigner();
  const contract = new Contract(
    process.env.REACT_APP_PYTHEAS,
    PYTHEAS_ABI,
    signer
  );
  const gasEstimate = await contract.estimateGas.addManyToPytheasAndCrew(
    account,
    tokenIds
  );
  return await contract.addManyToPytheasAndCrew(account, tokenIds, {
    gasLimit: gasEstimate.mul(BigNumber.from(12)).div(BigNumber.from(10)),
  });
};

export const claim = async (tokenIds, unstake) => {
  const provider = _getProvider();
  if (!provider) throw new Error("Unable to connect to wallet");
  const signer = provider.getSigner();
  const contract = new Contract(
    process.env.REACT_APP_PYTHEAS,
    PYTHEAS_ABI,
    signer
  );
  const gasEstimate = await contract.estimateGas.claimManyFromPytheasAndCrew(
    tokenIds,
    unstake
  );
  return await contract.claimManyFromPytheasAndCrew(tokenIds, unstake, {
    gasLimit: gasEstimate.mul(BigNumber.from(12)).div(BigNumber.from(10)),
  });
};

export const parseClaims = (receipt) => {
  const pytheas = new utils.Interface(PYTHEAS_ABI);
  const claims = [];
  receipt.logs.forEach((log) => {
    try {
      const args = pytheas.parseLog(log).args;
      if (args.tokenId) claims.push(args);
    } catch (error) {}
  });
  return claims;
};
