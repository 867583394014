import MainnetConnect from "./MainnetConnect"
import MetamaskConnect from "./MetamaskConnect"
import Button from "react-bootstrap/Button";

const EthereumInteraction = ({wallet, chain, children}) => {
    return (
        <>
            {
                wallet ? (
                    chain === process.env.REACT_APP_CHAIN ? (
                        children
                    ) : (

                        <Button>
                            <MainnetConnect/>
                        </Button>
                    )
                ) : (
                    <Button>
                        <MetamaskConnect/>

                    </Button>
                )
            }
        </>
    )
}

export default EthereumInteraction