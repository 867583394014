import { BigNumber, Contract } from "ethers";
import { _getProvider } from "./ethereum";
import { useState, useEffect } from "react";
import EON_ABI from "./abi/eon.abi";

export const eonBalance = async (address) => {
  const provider = _getProvider();
  if (!provider) return BigNumber.from("0");
  try {
    const signer = provider.getSigner();
    const contract = new Contract(process.env.REACT_APP_EON, EON_ABI, signer);
    return await contract.balanceOf(address);
  } catch (e) {
    console.log(e);
    return BigNumber.from("0");
  }
};

const MAX_APPROVE =
  "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

export const approve = async (token, spender) => {
  const provider = _getProvider();
  if (!provider) throw new Error("Unable to connect to wallet");
  const signer = provider.getSigner();
  const contract = new Contract(token, EON_ABI, signer);
  return await contract.approve(spender, MAX_APPROVE);
};

export const BalanceHook = () => {
  const [eon, setEon] = useState("?");

  useEffect(() => {
    const load = async () => {
      try {
        setEon(await eonBalance());
      } catch (error) {
        return error;
      }
    };
    load();
  }, []);

  return {
    eon,
  };
};
