import {useEffect, useState} from "react";
import TokenList from "./TokenList";
import LoadingModal from "./LoadingModal";
import OutcomeModal from "./OutcomeModal";
import UnstakeModal from "./UnstakeModal";
import EthereumInteraction from "./EthereumInteraction";
import {parseClaims, stake, claim} from "../utils/pytheas";
import {parseBigNumber, watchTransaction} from "../utils/ethereum";
import {BigNumber} from "@ethersproject/bignumber";
import CoralButton from "./CoralButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Terry from "../assets/images/avatars/Tezza.png";
import Swamp from "../assets/images/avatars/Swamp.png";
import Blackbird from "../assets/images/avatars/Blackbird.png";
import Blackout from "../assets/images/avatars/Blackout.png";
import J3spa from "../assets/images/avatars/J3spa.png";
import Bill from "../assets/images/avatars/Bill.png";

import "../assets/css/Staking.css";
import 'react-slideshow-image/dist/styles.css'


const Staking = ({
                     fetching,
                     tokens,
                     stakes,
                     wallet,
                     chain,
                     reload,
                     stats,
                     eon,
                 }) => {
    const [loadingScenes, setLoadingScenes] = useState([]);
    const [outcomes, setOutcomes] = useState([]);
    const [operation, setOperation] = useState(null);
    const [selected, setSelected] = useState([]);
    const [selectedAll, setSelectedAll] = useState(false);

    const [isUnstaking, setIsUnstaking] = useState(false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [transacting, setTransacting] = useState(false);

    useEffect(() => {
        if (selected.length === 0) setOperation(null);
    }, [selected]);

    const onStake = async () => {
        setLoading(true);
        setError(null);
        try {
            const hash = (
                await stake(
                    wallet,
                    selected.map((x) => x.number)
                )
            ).hash;
            setTransacting(true);
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setError("Stake failed. Check transaction.");
                }
                const o = [];
                for (let i = 0; i < selected.length; i++) {
                    const message = selected[i].isColonist
                        ? `Colonist #${selected[i].number} landed on Pytheas`
                        : `Pirate #${selected[i].number} joined the crew`;
                    const source = selected[i].isColonist
                        ? "/images/staked-pytheas.gif"
                        : "/images/staked-crew.gif";
                    o.push({message, source});
                }
                setOutcomes(o);
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const onClaim = async (unstake) => {
        setLoading(true);
        setError(null);
        try {
            const hash = (
                await claim(
                    selected.map((x) => x.number),
                    unstake
                )
            ).hash;
            setTransacting(true);
            setIsUnstaking(false);
            watchTransaction(hash, async (receipt, success) => {
                if (!success) {
                    setLoading(false);
                    setTransacting(false);
                    return setError("Unstake failed. Check transaction.");
                }
                presentOutcomes(receipt);
                setLoading(false);
                setTransacting(false);
            });
        } catch (e) {
            console.log(e);
            setLoading(false);
        }
    };

    const canUnstake = () => {
        const timestamp = Math.floor(Date.now() / 1000);
        for (let i in selected) {
            if (!selected[i].isColonist) continue;
            if (parseInt(selected[i].value) + 2 * 24 * 60 * 60 > timestamp)
                return false;
        }
        return true;
    };

    const presentOutcomes = (receipt) => {
        const claims = parseClaims(receipt);
        const o = [];
        for (let i = 0; i < claims.length; i++) {
            const token = selected.find(
                (el) => el.number === claims[i].tokenId.toString()
            );
            if (token.isColonist) {
                if (claims[i].unstaked) {
                    if (claims[i].earned.eq(BigNumber.from(0))) {
                        o.push({
                            message: `Colonist #${token.number} left Pytheas, but was intercepted all $EON was stolen by Pirates!`,
                            source: "/images/unstaked-notsafe.gif",
                        });
                    } else {
                        o.push({
                            message: `Colonist #${
                                token.number
                            } left pytheas and evaded the Pirates, earning ${parseBigNumber(
                                claims[i].earned
                            )} $EON`,
                            source: "/images/unstaked-safe.gif",
                        });
                    }
                } else {
                    o.push({
                        message: `Colonist #${token.number} was harvested for ${parseBigNumber(
                            claims[i].earned
                        )} $EON, after paying a 20% tax to the Pirates.`,
                        source: "/images/harvested.gif",
                    });
                }
            } else {
                if (claims[i].unstaked) {
                    o.push({
                        message: `Pirate #${
                            token.number
                        } left the crew, and received ${parseBigNumber(
                            claims[i].earned
                        )} $EON!`,
                        source: "/images/unstaked-pack.gif",
                    });
                } else {
                    o.push({
                        message: `Pirate #${token.number} collected a tax of ${parseBigNumber(
                            claims[i].earned
                        )} $EON!`,
                        source: "/images/claimed-pack.gif",
                    });
                }
            }
        }
        setOutcomes(o);
    };

    return (
        <>
            <section className="text-center">
                <h2>STAKING</h2>
            </section>

            <Row className="unstaked">
                <Col className="staking-images-row">
                    <h3>UNSTAKED</h3>
                    <div className="image-box">
                        <img src={Bill} alt="Bill" className="staking-asset"/>
                    </div>
                    <div className="image-box">
                        <img src={Terry} alt="Bill" className="staking-asset"/>
                    </div>
                    <div className="image-box">
                        <img src={J3spa} alt="Bill" className="staking-asset"/>
                    </div>
                    <div className="image-box">
                        <img src={Swamp} alt="Bill" className="staking-asset"/>
                    </div>
                    <div className="image-box">
                        <img src={Blackout} alt="Bill" className="staking-asset"/>
                    </div>
                    <div className="image-box">
                        <img src={Blackbird} alt="Bill" className="staking-asset"/>
                    </div>
                </Col>

            </Row>
            <Row className="staked">
                <Col className="staking-images-row">
                    <h3>STAKED</h3>
                    <div className="image-box">
                        <img src={Bill} alt="Bill" className="staking-asset"/>
                        <span className="eon">10000 EON</span>
                    </div>
                    <div className="image-box">
                        <img src={Terry} alt="Bill" className="staking-asset"/>
                        <span className="eon">10000 EON</span>
                    </div>
                    <div className="image-box">
                        <img src={J3spa} alt="Bill" className="staking-asset"/>
                        <span className="eon">10000 EON</span>
                    </div>
                    <div className="image-box">
                        <img src={Swamp} alt="Bill" className="staking-asset"/>
                        <span className="eon">10000 EON</span>
                    </div>
                    <div className="image-box">
                        <img src={Blackout} alt="Bill" className="staking-asset"/>
                        <span className="eon">10000 EON</span>
                    </div>
                    <div className="image-box">
                        <img src={Blackbird} alt="Bill" className="staking-asset"/>
                        <span className="eon">10000 EON</span>
                    </div>
                </Col>
            </Row>


            <div className="flex flex-col items-center font-pixel gap-5">
                <div className="flex justify-between items-center font-console gap-2">
                    <div>$EON in your wallet:</div>
                    <div>{parseBigNumber(eon)} $EON</div>
                </div>
                <div className="subtitle mt-5">Unstaked</div>
                <EthereumInteraction wallet={wallet} chain={chain}>
                    {fetching ? (
                        <div className="text-center text-red font-console">Fetching...</div>
                    ) : (
                        <TokenList
                            title={"Can Stake"}
                            active={operation !== "CLAIM"}
                            items={tokens}
                            selected={selected}
                            toggleSelected={(item, select) => {
                                if (operation !== "STAKE" && operation !== null) return;
                                setOperation("STAKE");
                                setSelected((current) => {
                                    return select
                                        ? current.concat(item)
                                        : current.filter((el) => el.id !== item.id);
                                });
                            }}
                        />
                    )}
                    {/* <div style={{height:'80px'}} className="text-sm font-console flex items-center text-red text-center">
            Your Colonist, or eonf will be revealed after staking into the PYTHEAS
          </div> */}
                </EthereumInteraction>
                <div className="subtitle mt-5">Staked</div>
                <EthereumInteraction wallet={wallet} chain={chain}>
                    {fetching ? (
                        <div className="text-center text-red font-console">Fetching...</div>
                    ) : (
                        <div className="w-full flex flex-col justify-center items-center gap-2">
                            <TokenList
                                title={"PYTHEAS"}
                                active={operation !== "STAKE"}
                                items={stakes?.filter((el) => el.isColonist)}
                                selected={selected}
                                stats={stats}
                                toggleSelected={(item, select) => {
                                    if (operation !== "CLAIM" && operation !== null) return;
                                    setOperation("CLAIM");
                                    setSelected((current) => {
                                        return select
                                            ? current.concat(item)
                                            : current.filter((el) => el.id !== item.id);
                                    });
                                }}
                            />
                            <TokenList
                                title={"PirateCrew"}
                                active={operation !== "STAKE"}
                                items={stakes?.filter((el) => !el.isColonist)}
                                selected={selected}
                                stats={stats}
                                toggleSelected={(item, select) => {
                                    if (operation !== "CLAIM" && operation !== null) return;
                                    setOperation("CLAIM");
                                    setSelected((current) => {
                                        return select
                                            ? current.concat(item)
                                            : current.filter((el) => el.id !== item.id);
                                    });
                                }}
                            />
                            <div className="w-full flex flex-col md:flex-row justify-center items-center gap-1">
                                {/* {selected.length === 1 && (
                   <WoodButton width={150} height={80} fontSize="16px" title={'View on OpenSea'} loading={loading} onClick={() => {
                     window.open(`${process.env.REACT_APP_OPENSEA}/${process.env.REACT_APP_eonF}/${parseInt(selected[0].id)}`, "_blank")
                  }}/>
                )} */}
                                {operation === "CLAIM" && (
                                    <>
                                        <CoralButton
                                            width={150}
                                            height={80}
                                            fontSize="16px"
                                            title={"HARVEST $EON"}
                                            loading={loading}
                                            onClick={() => {
                                                const isClaimingColonist = !!selected.find(
                                                    (el) => el.isColonist
                                                );
                                                const isClaimingPirate = !!selected.find(
                                                    (el) => !el.isColonist
                                                );
                                                const scenes = [];
                                                if (isClaimingColonist)
                                                    scenes.push({
                                                        message: "Mining",
                                                        source: "/images/mining.gif",
                                                    });
                                                if (isClaimingPirate)
                                                    scenes.push({
                                                        message: "Collecting 20% tax",
                                                        source: "/images/claiming-pack.gif",
                                                    });
                                                setLoadingScenes(scenes);
                                                onClaim(false);
                                            }}
                                        />
                                        <div className={canUnstake() ? "" : "opacity-50"}>
                                            <CoralButton
                                                width={150}
                                                height={80}
                                                fontSize="16px"
                                                title={"harvest $EON AND UNSTAKE"}
                                                disabled={!canUnstake()}
                                                loading={loading}
                                                onClick={() => {
                                                    if (!canUnstake()) return;
                                                    const isUnstakingColonist = !!selected.find(
                                                        (el) => el.isColonist
                                                    );
                                                    if (isUnstakingColonist) {
                                                        setIsUnstaking(true);
                                                        return;
                                                    }
                                                    const isUnstakingPirate = !!selected.find(
                                                        (el) => !el.isColonist
                                                    );
                                                    const scenes = [];
                                                    if (isUnstakingColonist)
                                                        scenes.push({
                                                            message: "Loading cargo and leaving PYTHEAS",
                                                            source: "/images/unstaking-PYTHEAS.gif",
                                                        });
                                                    if (isUnstakingPirate)
                                                        scenes.push({
                                                            message: "Collecting tax and leaving the crew",
                                                            source: "/images/unstaking-pack.gif",
                                                        });
                                                    setLoadingScenes(scenes);
                                                    onClaim(true);
                                                }}
                                            />
                                        </div>
                                    </>
                                )}
                                {operation === "STAKE" && (
                                    <CoralButton
                                        width={150}
                                        height={80}
                                        fontSize="16px"
                                        title={"STAKE"}
                                        loading={loading}
                                        onClick={() => {
                                            const isStakingColonist = !!selected.find(
                                                (el) => el.isColonist
                                            );
                                            const isStakingPirate = !!selected.find(
                                                (el) => !el.isColonist
                                            );
                                            const scenes = [];
                                            if (isStakingColonist)
                                                scenes.push({
                                                    message: "Entering the PYTHEAS",
                                                    source: "/images/staking-PYTHEAS.gif",
                                                });
                                            if (isStakingPirate)
                                                scenes.push({
                                                    message: "Joining the Piratepack",
                                                    source: "/images/staking-pack.gif",
                                                });
                                            setLoadingScenes(scenes);
                                            onStake(selected);
                                        }}
                                    />
                                )}
                                {operation === null && (
                                    <div>
                                        <div
                                            style={{height: "80px"}}
                                            className="text-sm font-console flex items-center text-red text-center"
                                        >
                                            Select tokens to stake, harvest, or unstake
                                        </div>
                                        <div className="mb-5">
                                            <CoralButton title={!selectedAll ? "Select ALL" : "Deselect ALL"}
                                                         fontSize="16px" loading={loading} onClick={
                                                () => {
                                                    if (!selectedAll) {
                                                        setSelected(stakes);
                                                        setSelectedAll(true);
                                                    } else {
                                                        setSelected([]);
                                                        setSelectedAll(false);
                                                    }
                                                }
                                            }/>
                                        </div>
                                        {selectedAll && (<CoralButton title={"harvest and collect ALL"} fontSize="16px"
                                                                      loading={loading} onClick={
                                            () => {
                                                const isClaimingColonist = !!selected.find(
                                                    (el) => el.isColonist
                                                );
                                                const isClaimingPirate = !!selected.find(
                                                    (el) => !el.isColonist
                                                );
                                                const scenes = [];
                                                if (isClaimingColonist)
                                                    scenes.push({
                                                        message: "harvesting Colonist",
                                                        source: "/images/harvesting.gif",
                                                    });
                                                if (isClaimingPirate)
                                                    scenes.push({
                                                        message: "Collecting 20% tax",
                                                        source: "/images/claiming-pack.gif",
                                                    });
                                                setLoadingScenes(scenes);
                                                onClaim(false);
                                                setSelectedAll(false);
                                            }
                                        }/>)}
                                    </div>
                                )}
                            </div>
                            {operation === "CLAIM" && !canUnstake() && (
                                <div
                                    className="text-xs text-center text-red"
                                    style={{width: "300px"}}
                                >
                                    You can only unstake a Colonist if it has at least 2 days worth
                                    of $EON.
                                </div>
                            )}
                        </div>
                    )}
                </EthereumInteraction>
            </div>


            {error && <div className="text-sm text-red font-console">{error}</div>}
            <LoadingModal loadingScenes={loadingScenes} modalIsOpen={transacting}/>
            <OutcomeModal
                outcomes={outcomes}
                modalIsOpen={outcomes.length > 0}
                closeModal={() => {
                    reload();
                    setOutcomes([]);
                    setSelected([]);
                }}
            />
            <UnstakeModal
                modalIsOpen={isUnstaking}
                closeModal={() => {
                    setIsUnstaking(false);
                }}
                loading={loading}
                onClick={() => {
                    const isUnstakingColonist = !!selected.find((el) => el.isColonist);
                    const isUnstakingPirate = !!selected.find((el) => !el.isColonist);
                    const scenes = [];
                    if (isUnstakingColonist)
                        scenes.push({
                            message: "Loading cargo and leaving PYTHEAS",
                            source: "/images/unstaking-PYTHEAS.gif",
                        });
                    if (isUnstakingPirate)
                        scenes.push({
                            message: "Collecting tax and leaving the Piratepack",
                            source: "/images/unstaking-pack.gif",
                        });
                    setLoadingScenes(scenes);
                    onClaim(true);
                }}
            />
        </>
    );
};

export default Staking;
